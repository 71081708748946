.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;

  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  box-sizing: border-box;

  background-color: var(--color-mediumslateblue);
  font-family: var(--text-sm-leading-5-font-medium);

  font-size: 1.2rem;
  color: var(--indigo-100);
  z-index: 1000;
}

.chaching-logo {
  margin: 0 0 0 max(6.51vw - 1rem, 4.4rem);
  /* padding: max(1.8vw - 0.5rem, 1.2rem) 0rem; */
  padding: max(1.6vw - 0.5rem, 1.2rem) 0rem;
  /* width: max(14.88vw - 3rem, 9.8rem); */
  width: max(12vw - 3rem, 9.8rem);
  overflow: hidden;
  flex-shrink: 0;
}

.header-options {
  /* margin: 0 5rem 0 0; */
  margin: 0 max(4.65vw, 4rem) 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: max(2.32vw, 1.75rem);
  justify-content: flex-end;
  /* font-size: clamp(0.87rem, 1.02vw, 1.1rem); */
  font-size: clamp(0.87rem, 0.95vw, 0.9rem);
}

.search {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
}

.documentation,
.support {
  cursor: pointer;
  position: relative;
  line-height: 1.25rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--indigo-100);
}

.avatar-icon {
  cursor: pointer;

  --avatar-width: max(3.54vw - 1rem, 2rem);
  width: max(2.6vw - 1rem, 2rem);
  /* aspect-ratio: 1; */
  height: max(2.6vw - 1rem, 2rem);
  border-radius: var(--br-base);

  object-fit: cover;
}
