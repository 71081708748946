.how-it-works-advertisers-icon {
  position: absolute;

  top: max(13.93vh - 1rem, 6.25rem);
  /* top: 6.25rem; */
  left: max(3.4vw - 1rem, 1.94rem);
  /* left: 1.94rem; */
  width: max(7.55% - 1rem, 5.97rem);
  /* width: 5.97rem; */
}

.connect-chaching-container {
  position: absolute;

  top: max(19vh - 1rem, 9rem);
  /* top: 9rem; */
  left: max(15.16vw - 1rem, 12.19rem);
  /* left: 12.19rem; */

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.drive-incremental-sales {
  display: inline-block;
  width: max(25.9vw, 23.0625rem);

  font-feature-settings: "clig" off, "liga" off;

  font-style: normal;

  font-size: 2rem;
  /* line-height: max(3.225vw - 0.1rem, 2.625rem); */
  line-height: max(5.803vh - 0.15rem, 2.625rem);
  /* line-height: 2.625rem; */

  font-size: clamp(2rem, 2.325vw, 2.5rem);
  font-size: clamp(2rem, 2.325vw, 2.8rem);

  font-weight: 500;

  color: #000;
}

.connect-button {
  cursor: pointer;
  border: 0;
  border-radius: var(--br-7xs);

  background-color: var(--color-mediumslateblue);

  overflow: hidden;
  display: flex;
  flex-direction: row;

  box-shadow: var(--shadow-sm);
  margin: max(5.15vw, 4.37rem) 0 0 0;
  /* margin: 4.37rem 0 0 0; */
  padding: max(1.21vw - 0.2rem, 0.8125rem) max(2.4vw - 0.4rem, 1.5625rem);
  /* padding: 0.8125rem 1.5625rem; */

  width: fit-content;

  font-size: 1.3rem;
  font-size: 1rem;
  font-size: clamp(1rem, 1.19vw, 1.3rem);
  line-height: 1.5rem;
  font-weight: 400;
  font-family: var(--text-sm-leading-5-font-medium);
  color: var(--white);
  text-align: left;
}

.connect-button:hover {
  background-color: var(--color-mediumslateblue-light);

  /* background-color: 3863fb;  */

  transition: 0.1s;
}

.connect-button:active {
  background-color: var(--color-mediumslateblue);
  background-color: var(--color-mediumslateblue-dark);
  transition: 0.1s;
}

.search-icon {
  position: relative;
  width: 9.75rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.search-icon {
  width: 1.25rem;
  height: 1.25rem;
}
.placeholder {
  position: relative;
  line-height: 1.25rem;
}

.navbarsavatar-menu-button {
  border-radius: var(--br-base);
  background-color: var(--white);
  overflow: hidden;
  justify-content: center;
}

.navbarsavatar-menu-button,
.profile-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-menu {
  padding: 0 0 0 1rem;
  justify-content: flex-start;
}

.landing-page {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--gray-page-background);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--white);
  font-family: var(--text-sm-leading-5-font-medium);
}

.landing-page-image {
  position: absolute;

  /* top: 26vh; */
  bottom: 23vh;
  right: 2.8vw;
  /* right: 2.38rem; */

  width: max(38%, 35.55rem);

  object-fit: cover;
  z-index: 2;
}

.footer {
  background-color: var(--color-mediumslateblue);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;

  color: var(--white);
  font-family: var(--text-sm-leading-5-font-medium);
}

.footer-paragraph-container {
  display: flex;
  flex-direction: column;

  margin: max(5.6vh, 2.69rem) 0 max(11.95vh, 5.81rem) max(14.55vw, 12.19rem);
  /* margin: 5.6vh 0 11.95vh 14.55vw; */
  line-height: max(2.2vw, 2rem);
  width: max(26.62%, 26.5rem);
  font-size: clamp(1.125rem, 1.34vw, 1.44rem);
}

.footer-paragraph-1 {
  font-weight: 400;
  display: inline-block;
  margin: 0 0 0.12rem 0;
}

.footer-paragraph-2 {
  font-weight: 400;
  margin: 0 0 0 0;
}

.chaching-link {
  text-decoration: underline;
  color: var(--white);
}

.footer-circle {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(62%, 59%);

  /* width: max(42.2%, 34.34875rem); */
  width: max(73vh, 34.34875rem);

  z-index: 100;
}
