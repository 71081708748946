/* @import url("https://fonts.googleapis.com/css2?family=Source Sans Pro:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source Sans Pro:wght@400&display=swap"); */

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --text-sm-leading-5-font-medium: Inter;
  --text-lg-leading-6-font-medium: Inter;
  --text-sm-leading-4-font-medium: Inter;
  --h1-page-header: "Source Sans Pro";
  --body-regular: "Source Sans Pro";

  /* font sizes */
  --text-sm-leading-5-font-semibold-size: 0.88rem;
  --text-sm-leading-5-font-medium-size: 0.88rem;
  --font-size-lg: 1.13rem;
  --text-base-leading-6-font-medium-size: 1rem;
  --text-2xl-leading-8-font-semibold-size: 1.5rem;
  --text-xs-leading-4-font-medium-tracking-wider-uppercase-size: 0.75rem;
  --text-lg-leading-6-font-medium-size: 1.13rem;
  --body-regular-size: 1rem;
  --text-sm-leading-4-font-medium-size: 0.88rem;
  --h1-page-header-size: 2rem;

  /* Colors */
  --gray-page-background: #f6f6f6;
  --color-mediumslateblue: #3863fb;
  --color-mediumslateblue-dark: #3055dc;
  --color-mediumslateblue-light: #4a6ff7;
  --white: #fff;
  --fills-and-strokes-white: #fff;
  --color-whitesmoke: #f7f7f7;
  --fills-and-strokes-divider-and-border: #d9dce9;

  --indigo-200: #c7d2fe;
  --color-mediumpurple: rgba(129, 140, 248, 0.25);
  --indigo-100: #e0e7ff;
  --indigo-600: #4f46e5;
  --gray-50: #f9fafb;
  --gray-page-background: #f6f6f6;
  --white: #fff;
  --indigo-500: #6366f1;
  --color-mediumslateblue-100: #3863fb;
  --green-600: #059669;
  --gray-900: #111827;
  --gray-500: #6b7280;
  --gray-300: #d1d5db;

  --gray-200: #e5e7eb;
  --gray-inactive: #d7d5d5;
  --error-dark: #a61c1c;
  --error-light: #ffe1e1;
  --red-100: #fee2e2;
  --red-800: #991b1b;
  --green-dark: #008135;
  --indigo-50: #eef2ff;
  --indigo-50-light: #f4f7ff;
  --indigo-50-dark: #e6ebfb;
  --indigo-600: #4f46e5;
  --gray-700: #374151;
  --type-primary: #313440;
  --green-100: #d1fae5;
  --green-800: #065f46;
  --indigo-200: #c7d2fe;
  --color-mediumpurple: rgba(129, 140, 248, 0.25);
  --indigo-100: #e0e7ff;
  --indigo-700: #4338ca;

  /* Gaps */
  --gap-base: 1rem;

  --gap-xl: 1.25rem;
  --gap-5xs: 0.5rem;
  --gap-9xs: 0.25rem;
  --gap-5xl: 1.5rem;
  --gap-xs: 0.75rem;

  /* Paddings */
  --padding-6xl: 1.56rem;
  --padding-base: 1rem;
  --padding-5xl: 1.5rem;
  --padding-13xl: 2rem;
  --padding-xs: 0.75rem;
  --padding-6xs: 0.44rem;
  --padding-4xs: 0.56rem;
  --padding-2xs: 0.69rem;
  --padding-mid: 1.06rem;
  --padding-11xs: 0.13rem;
  --padding-3xs: 0.63rem;
  --padding-5xs: 0.5rem;
  --padding-smi: 0.81rem;

  /* border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-9xs: 4px;
  --br-3xs: 10px;
  --br-base: 16px;
  --br-xl: 20px;

  /* Effects */
  --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-base: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}
