/* 

LAYOUT 

*/

.dashboard-page {
  width: 100%;
  min-height: 100dvh;
}

.main-content {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;

  background-color: var(--gray-page-background);
  text-align: left;
  font-size: var(--text-sm-leading-5-font-semibold-size);
  font-size: 0.88rem;
  color: var(--white);
  font-family: var(--text-lg-leading-6-font-medium);

  display: flex;
  justify-content: center;
}

.section-container {
  position: relative;
  display: flex;
  margin: 8.4rem 0;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  color: var(--gray-500);

  overflow-y: visible;
  /* margin-bottom: 5rem; */
  z-index: 30;
}

.content {
  align-self: stretch;
  gap: var(--gap-5xl);
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: flex-start;
}

.section {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: var(--shadow-base);
  padding: var(--padding-5xl);

  display: flex;
  flex-direction: column;

  /* overflow: hidden; */
}

.section.medium-size-font {
  font-size: var(--text-lg-leading-6-font-medium-size);
  color: var(--gray-900);
  gap: 1.5rem;
}

.heading {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.5rem;
  font-weight: 500;
  display: inline-block;
  width: 53.69rem;
  height: 2.06rem;
}

.heading.large {
  color: var(--essentials-grey-70, #313440);

  /* H2 - panel header */
  font-family: Source Sans Pro;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 133.333% */
}

.heading-parent {
  position: relative;
  width: 53.69rem;
  height: 2.06rem;
}

.supporting-text {
  position: relative;
  top: 0;
  left: 0;
  line-height: 1.25rem;
  display: inline-block;

  width: 53.69rem;
  font-size: var(--text-sm-leading-5-font-semibold-size);
  color: var(--gray-500);
}

.supporting-text-wrapper {
  position: relative;
  width: 46.19rem;
  height: 1.25rem;
}

.content-header {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.channel-chaching {
  position: relative;
  width: 2.03rem;
  height: 2.03rem;
  overflow: hidden;
  flex-shrink: 0;
}

.chaching {
  position: relative;
  line-height: 1.16rem;
}

.channel-name {
  justify-content: flex-start;
  gap: 0.29rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.secondary-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.14rem;
  font-size: 0.58rem;
  line-height: 0.87rem;
}

.chaching-info-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-direction: row;
  gap: 0.43rem;
  font-size: 0.87rem;
  color: var(--type-primary);
  font-family: var(--body-regular);
}

.connection-status-container {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.help-text {
  color: var(--gray-500, #6b7280);

  /* text-sm/leading-5/font-normal */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  width: 53.68rem;
}

.heading-container {
  position: relative;
  height: 2.06rem;
  font-size: var(--text-lg-leading-6-font-medium-size);
  color: var(--gray-900);
}

.supporting-text-frame {
  position: relative;
}

.connect-buttons-container {
  /* width: 50rem; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-direction: row;
  gap: 1.5rem;
  font-size: 0.87rem;
  color: var(--type-primary);
  font-family: var(--body-regular);
}

.or {
  color: var(--type-primary, #313440);
  font-family: Source Sans Pro;
  font-size: 0.86906rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15881rem; /* 133.333% */
}

.external-link-icon {
  position: relative;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}

.external-link-icon.white {
  filter: brightness(0) invert(1);
  padding-bottom: 0.02rem;

  /* paddin: 0.05rem; */
}

.line-break {
  width: 53.625rem;
  height: 0.0625rem;
  background: #333;
  border: none;
}

/* 

CONNECTED BADGE
*/

.badge {
  border-radius: var(--br-3xs);
  padding: var(--padding-11xs) var(--padding-3xs);
  justify-content: center;
  text-align: center;
  font-size: var(--text-xs-leading-4-font-medium-tracking-wider-uppercase-size);
  font-family: var(--text-lg-leading-6-font-medium);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.badge.green {
  background-color: var(--green-100);
  color: var(--green-800);
}

.badge.red {
  background-color: var(--red-100);
  color: var(--red-800);
}
/* 

DISCONNECT BUTTON
*/

.disconnect-icon {
  position: absolute;
  right: 0;
  cursor: pointer;

  z-index: 101;
}

.disconnect-popup-container {
  padding: 2rem 2rem;

  position: absolute;

  top: -0.7rem;
  right: -1.88rem;
  z-index: 100;
}

.disconnect-popup {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  width: 8.1875rem;
  padding: 0.5rem;
  align-items: center;
  gap: 2.5rem;
  z-index: 100;

  display: flex;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.disconnect-button {
  color: var(--Text---Secondary, #383838);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem; /* 150% */
  letter-spacing: 0.01563rem;
  border: none;
  background: #fff;
  cursor: pointer;
}

.disconnect-button:hover {
  color: var(--Text---Secondary, #383838c8);
}

/* 

LOGIN FORM

*/

.login-form {
  display: flex;
  flex-direction: column;

  gap: 1.5rem;

  align-items: flex-start;
  justify-content: flex-start;
}

.login-input-container {
  position: relative;
}
.login-input {
  display: flex;
  width: 24.3125rem;
  padding: 0.5rem;
  align-items: center;
  gap: 2.5rem;

  border: 1px solid var(--Gray---Lines, #e5e5e5);
}
.login-input-label {
  position: absolute;
  left: 0.22rem;
  top: -0.58rem;

  color: var(--Text---Disabled, #828282);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem; /* 175% */
  letter-spacing: 0.01563rem;
  background-color: white;

  padding: 0 0.25rem;
}

/* 

SELECT ACCOUNT BUTTON

*/

.select-account-container {
  position: relative;
}

.select-account-label {
  position: absolute;
  left: 0.22rem;
  top: -0.58rem;

  color: var(--Text---Disabled, #828282);
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem; /* 175% */
  letter-spacing: 0.01563rem;
  background-color: white;

  padding: 0 0.25rem;
}

.select-account {
  display: flex;
  width: 20.6875rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--Text---Secondary, #383838);

  appearance: none;
  background-image: url("../../assets/dashboard/keyboard_arrow_down.svg");
  background-repeat: no-repeat;
  background-position: right 0.5rem top 50%;
  background-size: 1.5rem auto;

  color: var(--Text---Secondary, #383838);

  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem; /* 150% */
  letter-spacing: 0.00625rem;
}

/* 

CONFIRM STEP SECTION

*/

.confirm-step-container {
}

.confirm-title {
  color: var(--gray-900, #111827);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 133.333% */
  margin-bottom: 1rem;
}
.confirm-checkbox-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 3rem;
}

.confirm-checkbox {
  padding: 0;
  margin: 0;
}
.confirm-label-text {
  color: var(--Text---Primary, #2a2c31);

  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  line-height: 1.3125rem; /* 150% */
  letter-spacing: 0.01563rem;
}

/* 

DARK BLUE BUTTON

*/

.dark-blue-button {
  display: flex;
  height: 2.5rem;
  padding: 0.5625rem 1rem;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  border: none;

  background: var(--color-mediumslateblue);
  color: white;

  text-align: center;

  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.00625rem;
  text-transform: uppercase;
  cursor: pointer;
}

.dark-blue-button:hover {
  background-color: var(--color-mediumslateblue-light);
  transition: 0.1s;
}
.dark-blue-button:active {
  background-color: var(--color-mediumslateblue-dark);
  transition: 0.1s;
}

.dark-blue-button.disabled {
  background: var(--gray-inactive, #d7d5d5);
  color: var(--Gray-01, #828282);
  cursor: auto;
}

/* 

WHITE BUTTON

*/

.white-button {
  display: flex;
  height: 2.5rem;
  padding: 0.5625rem 1rem;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  border: none;

  background: white;
  color: var(--color-mediumslateblue, #3863fb);

  text-align: center;

  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.00625rem;
  text-transform: uppercase;
  cursor: pointer;

  border: 1px solid var(--color-mediumslateblue, #3863fb);
}

.white-button:hover {
  background-color: var(--color-mediumslateblue);
  transition: 0.1s;
  color: white;
}
.white-button:active {
  background-color: var(--color-mediumslateblue);
  transition: 0.1s;
}

.white-button.disabled {
  background: var(--gray-inactive, #d7d5d5);
  color: var(--Gray-01, #828282);
}

/* 

RED BUTTON

*/

.red-button {
  display: flex;
  height: 2.5rem;
  padding: 0.5625rem 1rem;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  border: none;

  background: #b70037;
  color: white;

  text-align: center;

  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 100% */
  letter-spacing: 0.00625rem;
  text-transform: uppercase;
  cursor: pointer;
}

.red-button:hover {
  background-color: #d11a51;
  transition: 0.1s;
}
.red-button:active {
  background-color: #b70037;
  transition: 0.1s;
}

.red-button.disabled {
  background: var(--gray-inactive, #d7d5d5);
  color: var(--Gray-01, #828282);
}

/* 

GO TO CAMPAIGN BUTTON

*/

.go-to-campaign-button {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;

  border: none;
  border-radius: var(--br-9xs);
  background-color: var(--indigo-50);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-6xs) var(--padding-4xs) var(--padding-6xs)
    var(--padding-2xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--text-xs-leading-4-font-medium-tracking-wider-uppercase-size);
  color: var(--color-mediumslateblue-100);
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.go-to-campaign-button:hover {
  background-color: var(--indigo-50-light);
  transition: 0.1s;
}

.go-to-campaign-button:active {
  background-color: var(--indigo-50-dark);
  transition: 0.1s;
}

/* 

CONTACT BUTTON

*/

.contact-button {
  position: absolute;
  right: 3.5rem;
  top: 2.13rem;

  display: flex;
  padding: 0.375rem 1rem 0.375rem 0.5rem;
  align-items: center;
  gap: 0.25rem;

  border-radius: 0.25rem;
  border: 1px solid var(--accent-blue-40-primary, #3c64f4);
  background-color: white;
  cursor: pointer;
  color: var(--accent-blue-40-primary, #3c64f4);
}

.contact-button-text {
  /* Body regular */
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

.contact-button:hover {
  color: white;
  background-color: var(--accent-blue-40-primary, #3c64f4);
  border: 1px solid white;
  transition: 0.1s;
}

.contact-button:hover > .contact-button-mail-icon {
  filter: brightness(0) invert(1);
  transition: 0.1s;
}

.contact-button:active {
  color: white;
  background-color: var(--color-mediumslateblue-dark);
}

/*  

REFRESH BUTTON 

*/

.refresh-icon {
  position: relative;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}

.refresh-icon.refresh {
  animation: spin 1000ms infinite;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.refresh-button {
  position: absolute;
  top: 0;
  left: 48.13rem;
  border-radius: var(--br-9xs);
  background-color: var(--indigo-50);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-6xs) var(--padding-2xs) var(--padding-6xs)
    var(--padding-4xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--text-xs-leading-4-font-medium-tracking-wider-uppercase-size);
  color: var(--color-mediumslateblue-100);
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.refresh-button:hover {
  background-color: var(--indigo-50-light);
  transition: 0.1s;
}

.refresh-button:active {
  background-color: var(--indigo-50-dark);
  transition: 0.1s;
}

/* 

STATS

*/

.stats-section {
  margin-top: 1.5rem;
  display: flex;
}

.stat {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* width: 56.69rem; */

  border-radius: var(--br-5xs);
  overflow: hidden;
}

.leading-content {
  align-self: stretch;
  padding: var(--padding-5xl);
  gap: var(--gap-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.brand-icon {
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: center;
}

.brand-icon.green {
  background-color: var(--green-dark);
}

.brand-icon.red {
  background-color: var(--error-dark);
}

.brand-icon.blue {
  background-color: var(--color-mediumslateblue-100);
}

.brand-icon-img {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}

.label {
  position: relative;
  align-self: stretch;
  line-height: 1.25rem;
  font-weight: 500;
}
.amount {
  position: relative;
  line-height: 2rem;
  font-weight: 600;
}
.amount-and-change {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-5xs);
  font-size: var(--text-2xl-leading-8-font-semibold-size);
  color: var(--gray-900);
}

.divider {
  align-self: stretch;
  position: relative;
  background-color: var(--gray-200);
  width: 0.06rem;
}

/* 

TABLE

*/

.product-table {
  table-layout: fixed;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: var(--shadow-base);
  border: 1px solid var(--gray-200);

  margin-top: 1rem;

  width: 53.69rem;
  /* width: 100%; */
  overflow-y: auto;
  /* overflow-x: hidden; */

  border-spacing: 0;
}

.product-table > thead {
  display: block;
}

.table-header-title {
  position: relative;

  font-size: var(--text-xs-leading-4-font-medium-tracking-wider-uppercase-size);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: var(--gray-500);

  align-self: stretch;
  background-color: var(--gray-50);
  text-align: left;
  padding: var(--padding-xs) var(--padding-5xl);
}

.table-header-title.name {
  width: 10.5rem;
  /* min-width: 35%; */
}
.table-header-title.sku {
  /* width: 13.46rem; */
  width: 4.4rem;
}

.table-header-title.description {
  width: 29.69rem;
}

.table-header-title.wide-name {
  width: 14rem;
}

.table-header-title.wide-description {
  width: 38.125rem;
}

.header-divider {
  align-self: stretch;
  position: relative;
  background-color: #f0f1f3;
  height: 0.06rem;
}

.product-table > tbody {
  display: block;
}

.product-table > tbody {
  max-height: 19.23rem;
  overflow-y: auto; /* Trigger vertical scroll    */
  /* overflow-x: hidden; */
}

.table-cell {
  line-height: 1.25rem;
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl);
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-500);
  font-size: 0.88rem;
  overflow-wrap: break-word;
}

.table-cell.grey {
  background-color: var(--gray-50);
}

.table-cell.name {
  color: var(--gray-900);
  width: 10.5rem;
  max-width: 10.5rem;
}

.table-cell.sku {
  width: 4.5rem;
  max-width: 4.5rem;
}

.table-cell.description {
  width: 29.69rem;
  max-width: 29.69rem;
}

.table-cell.wide-name {
  color: var(--gray-900);
  width: 14rem;
}

.table-cell.wide-description {
  width: 38.125rem;
}

/* 

ALERT

*/

.alert-container {
  position: fixed;
  bottom: 1.63rem;

  display: inline-flex;
  height: 3rem;
  padding: 0.5rem 1rem;
  align-items: center;
  /* gap: 1.5rem; */
  flex-shrink: 0;
  background: var(--Green---Light, #ccfae4);
  left: 50%;
  transform: translate(-50%);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  animation: slide-up 0.6s;

  z-index: 1000;
}

.alert-container.hide {
  animation: slide-down 0.8s;
}

.alert-container.red {
  background: var(--error-light, #ffe1e1);
}

.alert-text {
  color: var(--Solid-Black, #000);
  font-family: Roboto;
  /* font-family: Source Sans Pro; */

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */

  margin: 0 1.5rem 0 0.89rem;
  white-space: nowrap;
}

.alert-close-icon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.alert-check-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.alert-close-icon:hover {
  filter: opacity(0.6);
}

@keyframes slide-up {
  from {
    transform: translate(-50%, 50%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes slide-down {
  0% {
    transform: translate(-50%, 0);
  }
  80% {
    transform: translate(-50%, 40%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 50%);
    opacity: 0;
  }
}

/* 

DISCONNECT MODAL

*/

.modal-background {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  height: 100dvh;
  width: 100dvw;

  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background-color: white;
  /* height: 10rem;
  width: 15rem; */

  display: inline-flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.3125rem;
  flex-shrink: 0;
}

.modal-title-container {
  display: flex;
  flex-direction: row;
  gap: 0.44rem;
}

.modal-title {
  color: var(--Solid-Black, #000);
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 2.25rem; /* 150% */
  margin: 0;
}

.modal-description {
  color: var(--Solid-Black, #000);

  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.03125rem;
}

.modal-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;
}
/* 
.modal-icon {
  width: 1.875rem;
  height: 1.875rem;
  flex-shrink: 0;
} */
