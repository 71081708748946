.install-page-content {
  position: relative;
  /* top: 0; */
  /* left: 0; */

  width: 100%;
  background-color: var(--fills-and-strokes-white);
  color: var(--fills-and-strokes-white);
  font-family: var(--text-sm-leading-4-font-medium);
  font-size: 0.75rem;
  text-align: left;

  display: flex;
  justify-content: center;
}

.layout {
  margin: 6.05rem 0;

  display: flex;
  flex-direction: column;
  gap: var(--gap-5xl);

  padding: 2rem 2rem 2rem 0rem;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.heading-text {
  position: relative;
  line-height: 2.5rem;
  font-weight: 300;
  display: inline-block;
  width: 43.06rem;
  font-size: var(--h1-page-header-size);
  color: var(--type-primary);
  font-family: var(--h1-page-header);
}

.panel {
  align-self: stretch;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: var(--gap-5xl);
  align-items: flex-start;
  justify-content: flex-start;

  padding: var(--padding-5xl);
  font-size: var(--text-sm-leading-4-font-medium-size);
  color: var(--gray-500);
  font-family: var(--text-sm-leading-4-font-medium);
}

.step {
  padding: var(--padding-base) var(--padding-5xl);
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.step.media {
  align-items: flex-start;
}

.step p {
  line-height: 1rem;
  font-weight: 500;
}

.step-text {
  display: flex;
  gap: var(--gap-base);
  align-items: center;
}

.step-number-container {
  border-radius: var(--br-xl);
  border: 2px solid var(--gray-300);
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step img {
  padding-top: 1rem;
  margin-left: 3.5rem;
  max-width: 50rem;
}

.image2 {
  /* height: 2rem; */
  width: 12rem;
}

.code-block {
  /* border: solid; */
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  max-width: 50rem;
  margin-left: 3.5rem;
  transition: 0.1s;
  cursor: pointer;
}
.code-block:hover {
  background-color: rgb(248, 247, 247);
}

.code-block:active {
  background-color: rgb(227, 227, 227);
}
